<template>
  <el-container>
    <div class="w-100">
      <div class="search-policy">
        <el-row>
          <el-card class="top-shadow-none">
            <el-form label-width="100px" class="form-box  demo-form-inline"
              :label-position="labelPosition">
              <el-form-item label="时间范围" class="w-50">
                <el-date-picker v-model="form.span"
                  type="daterange"
                  align="center"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="排序方式" class="w-50">
                <el-select v-model="form.order" placeholder="请选择" class="w-200">
                  <el-option
                    v-for="(item, index) in orderOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="相似合并" class="w-50">
                <el-radio-group v-model="form.merge"
                  key="6">
                  <el-radio v-for="item in mergeGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="信息匹配" class="w-50">
                <el-radio-group v-model="form.field"
                  key="4">
                  <el-radio v-for="item in infoMatchGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="行业筛选" class="w-50">
                <el-radio-group v-model="form.industry" key="4">
                  <el-radio label="none">不限</el-radio>
                  <el-radio label="focus">锁定</el-radio>
                </el-radio-group>
                <el-select v-show="form.industry === 'focus'"
                  v-model="form.industry_customer" class="mar-l-10">
                  <el-option v-for="(item, index) in industryOptions"
                    :key="index"
                    :label="item"
                    :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="信息地区" class="w-50">
                <el-radio-group v-model="form.area_cn"
                  key="7">
                  <el-radio v-for="item in areaGroup2"
                    :key="item.value"
                    :label="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
                <el-cascader
                    v-model="customerArea"
                    :options="provinceOptions"
                    @change="handleCityChange"
                    class="mar-l-10"
                    v-if="form.area_cn === 'customer'" />
              </el-form-item>
              <el-form-item label="发文机构" class="w-50">
                <el-radio-group v-model="form.department"
                  key="4">
                  <el-radio v-for="item in departmentGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
                <el-select v-show="form.department === 'customer'"
                  v-model="form.department_customer"
                  key="8" class="mar-l-10">
                  <el-option v-for="item in departmentList"
                    :key="item"
                    :label="item"
                    :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="再检索" class="hide">
                <el-select key="12"
                  placeholder="简单检索"
                  class="w-120 f-l mar-r-20"
                  v-model="searchType">
                  <el-option v-for="item in searchTypeGroup"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
                <div class="f-l mar-r-10">
                  <div v-show="searchType === 'simple'"
                    class="ov-h">
                    <el-input placeholder="请输入关键词"
                      v-model="form.deep_exps.simple.word"
                      class="w-200 mar-r-10"></el-input>
                    <el-input placeholder="请输入来源"
                      v-model="form.deep_exps.simple.source"
                      class="w-200 mar-r-10"></el-input>
                  </div>
                  <div v-show="searchType === 'high'"
                    class="ov-h">
                    <div class="ov-h">
                      <el-input placeholder="多个关键词请用英文逗号隔开"
                        v-model="form.deep_exps.high.word"
                        class="w-200 mar-r-10"></el-input>
                      <el-select placeholder="或"
                        class="w-80 mar-r-10"
                        v-model="form.deep_exps.high.word_op">
                        <el-option label="或"
                          value="or"></el-option>
                        <el-option label="与"
                          value="and"></el-option>
                      </el-select>
                      <el-input placeholder="多个排除关键词请用英文逗号隔开"
                        v-model="form.deep_exps.high.not_word"
                        class="w-200 mar-r-10"></el-input>
                      <el-select placeholder="或"
                        class="w-80 mar-r-10"
                        v-model="form.deep_exps.high.not_word_op">
                        <el-option label="或"
                          value="or"></el-option>
                        <el-option label="与"
                          value="and"></el-option>
                      </el-select>
                    </div>
                    <div class="ov-h mar-t-10">
                      <el-input placeholder="多个来源请用英文逗号隔开"
                        v-model="form.deep_exps.high.source"
                        class="w-200 mar-r-10"></el-input>
                      <el-input placeholder="多个排除来源请用英文逗号隔开"
                        v-model="form.deep_exps.high.not_source"
                        class="w-200 mar-r-10"></el-input>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <!-- 按钮组 -->
            <div class="button-group mar-t-30">
              <el-button type="primary" round
                v-loading="loading"
                :disabled="loading"
                @click="handleClickFilter">查询</el-button>
              <el-button round @click="handleClickReset">重置</el-button>
            </div>
          </el-card>
        </el-row>
        <el-card class="box-card mar-t-20 xxlb">
          <div class="mod-title mar-t-20">信息列表</div>
          <div class="box-shadow"></div>
          <xxlb ref="list" :orderOptions="orderOptions" @exportData="exportData" @done="loadListDone"></xxlb>
        </el-card>
      </div>
    </div>
    <article-detail ref="detail" :materials="materials" />
  </el-container>
</template>
<script type="application/javascript">
import {
  infoMatchGroup,
  mergeGroup,
  areaGroup2,
  searchTypeGroup,
  orderGroup,
  departmentGroup,
  industryOptions
} from '../constants';
import { departmentList, orderOptions, pickerOptions, province, provinceOptions } from '@/utils/constants';
import { getPolicyList } from '../api';
import articleDetail from '@components/common/article-detail.vue';
import xxlb from '@components/common/list.vue';
import { numberFormat, parseTime, getAuthToken, queryString, handleDownload } from '@/utils/helpers.js';
import { lists as materialList, add_item } from "@/api/material";
export default {
  name: 'ComponetPolicy',
  components: {
    articleDetail,
    xxlb
  },
  props: {
    word: {
      type: String,
      default: '',
    },
    isShowFrom: {
      type: Boolean,
      default: false,
    },
    isEmitSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderOptions: orderOptions,
      provinceOptions,
      numberFormat,
      loading: false,
      infoMatchGroup,
      mergeGroup,
      areaGroup2,
      orderGroup,
      searchTypeGroup,
      departmentGroup,
      pickerOptions,
      industryOptions,
      departmentList,
      searchType: 'simple',
      province: province,
      labelPosition: 'left',
      customerArea: [],
      // 政策 form 参数
      form: {
        page: 1,
        size: 20,
        order: 'intelligence',
        stat: 'yes',
        span: [],
        merge: mergeGroup[0].value,
        department: departmentGroup[0].value,
        department_customer: departmentList[0],
        industry: 'none',
        industry_customer: industryOptions[0],
        area_cn: areaGroup2[0].value,
        city: '',
        area_customer: province[0],
        field: infoMatchGroup[0].value,
        deep_exps: {
          simple: {
            word: '',
            source: '',
          },
          high: {
            word: '',
            word_op: '',
            not_word: '',
            source: '',
            not_source: '',
            not_word_op: 'or',
          },
        },
      },
      initForm: {},
      // 信息总量统计
      infoTotal: {
        total: 0, // 总量
        list: [], // 每一项的统计
      },
      listTotal: 0,
      data: [],
      checkedAll: false,
      checkbox: false,
      checkedData: {},
      handleData: false,
      joinMaterial: { visible: false, selectedId: '', loading: false },
      materials: [],
      dialogVisible: false,
    };
  },
  computed: {
    emit: {
      get() {
        return this.isEmitSearch;
      },
      set(val) {
        this.$emit('update:isEmitSearch', val);
      },
    },
  },
  created() {
    // 设置默认时间
    const currTime = new Date().getTime();
    const start = currTime - 3600 * 1000 * 24 * 30;
    const format = 'yyyy-mm-dd';
    this.form.span = [parseTime(start, format), parseTime(currTime, format)];
    this.handleClickFilter();
    let where = JSON.parse(JSON.stringify(this.form))
    delete where.type
    delete where.page
    delete where.size
    delete where.order
    delete where.stat
    this.initForm = where
  },
  watch: {
    emit: function(val) {
      if (val) {
        this.loadPolicyData();
      }
    },
  },
  mounted() {
    this.loadMaterial();
  },
  methods: {
    // 政策接口
    loadPolicyData() {
      const query = this.combinForm();
      if (!query) return false;
      this.loading = true;
      this.$refs.list.loadData(`${window.service.api}/search/policy`, getPolicyList(query), false, 'search-policy');
    },
    loadListDone(data, params = {}) {
      Object.assign(this.form, params)
      this.loading = false;
    },
    // 查询
    handleClickFilter() {
      this.$nextTick(() => {
        this.loadPolicyData();
      })
    },
    handleCityChange(v) {
      if (v && v.length >= 2) {
        Object.assign(this.form, {
          area_customer: v[0],
          city: v[0] !== v[1] ? v[1] : ''
        })
      }
    },
    // 选择
    checkChange(checked, rowkey) {
      if (checked == false) {
        this.checkedAll = false;
      } else {
        // 判断全选状态
        var state = true;
        _(this.data).forEach((item, i) => {
          if (!item.checked) state = false;
        });
        this.checkedAll = state;
      }
      this.checkedData[rowkey] = checked;
    },
    cancelSelected() {
      var _this = this;
      this.checkbox = false;
      this.checkedAll = false;
      var selected = {};
      for (let key in this.checkedData) {
        selected[key] = false;
      }
      this.checkedData = selected;
      _(this.data).forEach((item, i) => {
        _this.data[i].checked = false;
      });
    },
    // 全选
    allChecked() {
      this.checkedAll = true;
      this.checkbox = true;
      _(this.data).forEach((item, i) => {
        this.checkedData[item.rowkey] = true;
        this.data[i].checked = true;
      });
    },
    cancelAllChecked() {
      var _this = this;
      this.checkedAll = false;
      _(this.data).forEach((item, i) => {
        _this.checkedData[item.rowkey] = false;
        _this.data[i].checked = false;
      });
    },
    showJoinDialog() {
      var ids = [];
      for (let id in this.checkedData) {
        if (this.checkedData[id]) ids[ids.length] = id;
      }
      if (ids.length < 1) {
        this.$message.error('请选择要加入的素材');
        return false;
      }
      this.joinMaterial.visible = !this.joinMaterial.visible;
    },
    orderChange(order) {
      Object.assign(this.form, {page: 1});
      this.loadPolicyData();
    },
    exportData(total) {
      const params = this.buildParams();
      params.token = getAuthToken();
      const query = decodeURIComponent(queryString(params, true));

      handleDownload(`${window.service.api}/search/excel${query}`, 'GET', `${window.$moment().format("YYYY-MM-DD")}.xlsx`, null, total, () => {
        this.$refs['list'].exportLoading = false
      })
    },
    loadMaterial() {
      materialList().then(res => {
          if (res.data.state) {
            var result = res.data.data;
            this.materials = result;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('加载收藏夹失败，服务错误');
        });
    },
    addMaterialAction(materialId) {
      if (!materialId) {
        this.$message.error('请选择收藏夹');
        return false;
      }
      var ids = [];
      for (let id in this.checkedData) {
        if (this.checkedData[id]) ids[ids.length] = id;
      }
      if (ids.length < 1) {
        this.$message.error('请选择要加入的素材');
        return false;
      }
      this.joinMaterial.loading = true;
      add_item({
          id: materialId,
          ids: ids.join(','),
        }).then(res => {
          if (res.data.state) {
            this.$message.success('添加成功');
            this.joinMaterial.loading = false;
            this.joinMaterial.visible = false;
            this.cancelSelected();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('添加素材失败，服务错误');
        });
    },
    errorTips(str) {
      this.$message.error(str);
      return false;
    },
    // 查询前验证
    validForm() {
      return true;
    },
    buildParams() {
      const form = this.combinForm()
      if (!form) return false;
      const params = {
        word: this.word,
        span: form.span,
        area: form.area_cn,
        city: form.city,
        focus: JSON.stringify(form.deep_exps),
        department: form.department,
        field: form.field,
        similar: form.merge,
        industry: form.industry,
        search: 'yes',
        source_type: 'policy',
      }
      return params;
    },
    // 组合参数
    combinForm() {
      const {
        span,
        area_cn,
        city,
        area_customer,
        department,
        department_customer,
        industry,
        industry_customer,
        deep_exps,
        ...commonObj
      } = this.form;
      if (!span) {
        this.$message.warning('时间不能为空');
        return false;
      }
      const { searchType } = this;
      const obj = Object.assign(commonObj, {
        span: span.join('~'),
        word: this.word,
        area_cn: area_cn === 'customer' ? area_customer : area_cn,
        city: area_cn === 'customer' ? city : '',
        department: department === 'customer' ? department_customer : department,
        deep_exps: deep_exps[`${searchType}`],
        industry: industry === 'focus' ? industry_customer : 'all'
      });
      delete obj.industry_customer
      delete obj.department_customer
      return obj;
    },
    // 取消
    handleClickReset() {
      let where = JSON.parse(JSON.stringify(this.initForm))
      Object.assign(this.form, where)
      this.form.deep_exps = where.deep_exps
    },
    target(url) {
      window.open(url, '_blank');
    },
    detail(rowkey) {
      let pid = '';
      let keywords = [];
      const { word, source, not_word, not_source } = this.form.deep_exps;
      let focusWords = word || source || not_word || not_source;
      if (_.size(focusWords) >= 1) {
        let words = focusWords.split(',');
        _(words).forEach((word, index) => {
          if (keywords.indexOf(word) === -1) keywords.push(word);
        });
      }
      this.$refs.detail.showDetail(rowkey, 'list', keywords, pid);
    },
    addRowMaterialAction(material, id) {
      if (!material.selectId) {
        this.$message.error('请选择素材库');
        return false;
      }
      material.loading = true;
      add_item({
          id: material.selectId,
          ids: id,
        }).then(res => {
          if (res.data.state) {
            this.$message.success('添加成功');
            material.loading = false;
            material.visible = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('添加素材失败，服务错误');
        });
    },
  },
};
</script>
<style scoped>
.search-policy >>> .el-card__body{padding:0 40px 30px;}
.search-policy .form-box{margin-bottom: 20px;margin-top: 0;background:none;padding:0;}
.el-card{border:none;}
/* 2.0.6迭代 */
.search-policy .demo-form-inline .w-50{width:50%;display: inline-block;}
.search-policy >>> .el-form-item__label{font-weight: 600;}
</style>
