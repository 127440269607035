
import {
  timeGroup,
  emotionGroup,
  infoMatchGroup,
  infoTypeGroup,
  mergeGroup,
  areaGroup,
  weiboGroup,
  weiboUserGroup,
  languageGroup,
  departmentGroup,
} from './constants';
import { medias as mediaGroup } from '@/utils/constants.js';

// 信息列表检索
export function getInfoList(params) {
  const obj = Object.assign({
    span: timeGroup[0].value,
    media: mediaGroup.join(','),
    emotion: emotionGroup[0].value,
    merge: mergeGroup[0].value,
    area_cn: areaGroup[0].value,
    weibo_forward: weiboGroup[0].value,
    weibo_usertype: weiboUserGroup[0].value,
    is_policy: infoTypeGroup[0].value,
    field: infoMatchGroup[0].value,
    lang: languageGroup[0].value,
    deep_exps: {}
  }, params);

  return obj;
}

// 来源 - 信息 检索
export function getSourceInfoList(params) {
  const obj = Object.assign({
    type: 'info',
    span: timeGroup[0].value,
    media: mediaGroup.join(','),
    emotion: emotionGroup[0].value,
    merge: mergeGroup[0].value,
    area_cn: areaGroup[0].value,
    weibo_forward: weiboGroup[0].value,
    weibo_usertype: weiboUserGroup[0].value,
    is_policy: infoTypeGroup[0].value,
    field: infoMatchGroup[0].value,
    lang: languageGroup[0].value,
    deep_exps: {},
  }, params);

  return obj;
}

// 政策检索
export function getPolicyList(params) {
  const obj = Object.assign({
    span: [],
    merge: mergeGroup[0].value,
    department: departmentGroup[0].value,
    department_customer: '',
    area_cn: areaGroup[0].value,
    field: infoMatchGroup[0].value,
    deep_exps: {},
  }, params);

  return obj;
}
