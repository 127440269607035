export const typesGroup = [
  {
    label: '信息',
    value: 'info',
  },
  {
    label: '来源',
    value: 'source',
  },
  {
    label: '政策',
    value: 'policy',
  },
];

export const timeGroup = [
  {
    value: 'today',
    label: '今天',
  },
  {
    value: '1',
    label: '24小时',
  },
  {
    value: '2',
    label: '2天',
  },
  {
    value: '3',
    label: '3天',
  },
  {
    value: '7',
    label: '7天',
  },
];

export const industryOptions = [
  "农业/农村",
  "财政/金融",
  "军事",
  "教育",
  "文学/艺术",
  "科学技术",
  "体育",
  "对外关系/国际关系",
  "传媒业",
  "矿业/工业",
  "电子信息产业",
  "政治",
  "文化/休闲娱乐",
  "交通运输/邮政/物流",
  "社会/劳动",
  "灾难/事故",
  "经济",
  "基本建设/建筑业/房地产",
  "服务业/旅游业",
  "医药/卫生",
  "环境/气象",
  "法律/司法",
  "能源/水务/水利",
  "商业/外贸/海关"
];

export const emotionGroup = [
  {
    value: 'all',
    label: '全部',
  },
  {
    value: 'negative',
    label: '敏感',
  },
  {
    value: 'positive',
    label: '非敏感',
  }
];

export const infoMatchGroup= [
  {
    value: 'content',
    label: '全文匹配',
  },
  {
    value: 'title',
    label: '标题匹配',
  },
];
export const infoTypeGroup= [
  {
    value: 'all',
    label: '全部',
  },
  {
    value: 'policy',
    label: '政策',
  },
];

export const mergeGroup = [
  {
    value: 'no',
    label: '不合并',
  },
  {
    value: 'yes',
    label: '合并',
  },
];

export const areaGroup = [
  {
    value: 'all',
    label: '全部',
  },
  {
    value: 'cn',
    label: '境内',
  },
  {
    value: 'other',
    label: '境外',
  },
  {
    value: 'customer',
    label: '自定义',
  }
];

export const areaGroup2 = [
  {
    value: 'all',
    label: '全部',
  },
  {
    value: 'customer',
    label: '自定义',
  }
];

export const weiboGroup = [
  {
    value: 'all',
    label: '不限',
  },
  {
    value: 'yes',
    label: '原创',
  },
  {
    value: 'no',
    label: '转发',
  },
];

export const weiboUserGroup = [
  {
    value: 'all',
    label: '不限',
  },
  {
    value: '普通用户',
    label: '普通用户',
  },
  {
    value: '黄V',
    label: '黄V',
  },
  {
    value: '金V',
    label: '金V',
  },
  {
    value: '蓝V',
    label: ' 蓝V',
  },
  {
    value: '达人',
    label: '达人',
  },
  {
    value: '微博女郎',
    label: '微博女郎'
  }
];

export const languageGroup = [
  {
    value: 'all',
    label: '全部',
  },
  {
    value: 'zh',
    label: '中文'
  },
  {
    value: 'en',
    label: '其他',
  },
];

export const orderGroup = [
  {
    value: 'join_desc',
    label: '加入素材时间降序',
  },
  {
    value: 'join_asc',
    label: '加入素材时间升序',
  },
  {
    value: 'pub_desc',
    label: '发布时间降序',
  },
  {
    value: 'pub_asc',
    label: '发布时间升序',
  },
];

export const searchTypeGroup = [
  {
    value: 'simple',
    label: '简单检索',
  },
  {
    value: 'high',
    label: '高级检索',
  },
];

export const departmentGroup = [
  {
    value: 'all',
    label: '全部',
  },
  {
    value: 'customer',
    label: '自定义',
  }
]

// 相关来源图片映射
export const sourceImgMap = {
  '微信': require('@/assets/images/common/img_weixin@3x.png'),
  '论坛': require('@/assets/images/common/img_luntan@3x.png'),
  '报刊': require('@/assets/images/common/img_baokan@3x.png'),
  '网媒': require('@/assets/images/common/img_wangmei@3x.png'),
  '政务': require('@/assets/images/common/img_zhengwu@3x.png'),
  '博客': require('@/assets/images/common/img_boke@3x.png'),
  '微博': require('@/assets/images/common/img_weibo@3x.png'),
}
