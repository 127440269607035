<template>
  <el-container>
    <div class="w-100">
      <div class="search-info mar_t-10">
        <el-row>
          <el-card class="top-shadow-none">
            <el-form label-width="100px" class="form-box demo-form-inline">
              <el-form-item label="时间范围" class="w-50">
                <el-radio-group v-model="form.span" key="1">
                  <el-radio v-for="item in timeGroup" :key="item.value" :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="排序方式" class="w-50">
                <el-select v-model="form.order" placeholder="请选择" class="w-200">
                  <el-option
                    v-for="(item, index) in orderOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="信息等级" class="w-50">
                <el-radio-group v-model="form.msg_level">
                  <el-radio label="all">全部</el-radio>
                  <el-radio label="normal">精准信息</el-radio>
                  <el-radio label="garbage">噪音信息</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="情感属性" class="w-50">
                <el-radio-group v-model="form.emotion" key="3">
                  <el-radio v-for="item in emotionGroup" :key="item.value" :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="相似合并" class="w-50">
                <el-radio-group v-model="form.merge" key="6">
                  <el-radio v-for="item in mergeGroup" :key="item.value" :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="信息匹配" class="w-50">
                <el-radio-group v-model="form.field" key="4">
                  <el-radio v-for="item in infoMatchGroup" :key="item.value" :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <el-form label-width="100px" class="form-box demo-form-inline" :label-position="labelPosition" v-show="isShowFrom">
              <el-form-item label="信息类型" class="hide">
                <el-radio-group v-model="form.is_policy" key="5">
                  <el-radio v-for="item in infoTypeGroup" :key="item.value" :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="信息地区" class="w-50">
                <el-radio-group v-model="form.area_cn" key="7">
                  <el-radio v-for="item in areaGroup" :key="item.value" :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
                <el-cascader
                    v-model="customerArea"
                    :options="provinceOptions"
                    @change="handleCityChange"
                    class="mar-l-10"
                    v-if="form.area_cn === 'customer'" />
              </el-form-item>
              <el-form-item label="语言" class="w-50">
                <el-radio-group v-model="form.lang" key="11">
                  <el-radio v-for="item in languageGroup" :key="item.value" :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="微博筛选">
                <span class="mar-r-10 fz-12">微博类型</span>
                <el-select v-model="form.weibo_forward" placeholder="活动区域" class="mar-r-20 w-120">
                  <el-option label="不限" value="all"></el-option>
                  <el-option label="转发" value="yes"></el-option>
                  <el-option label="原创" value="no"></el-option>
                </el-select>
                <span class="mar-r-10 fz-12">微博认证</span>
                <el-select v-model="form.weibo_usertype" placeholder="活动区域" class="w-120">
                  <el-option label="不限" value="all"></el-option>
                  <el-option label="普通用户" value="普通用户"></el-option>
                  <el-option label="黄V" value="黄V"></el-option>
                  <el-option label="金V" value="金V"></el-option>
                  <el-option label="蓝V" value="蓝V"></el-option>
                  <el-option label="达人" value="达人"></el-option>
                  <el-option label="微博女郎" value="微博女郎"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="再检索" class="hide">
                <el-select key="12" placeholder="简单检索" class="w-120 f-l mar-r-20" v-model="searchType">
                  <el-option v-for="item in searchTypeGroup" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <div class="f-l mar-l-10">
                  <div v-show="searchType === 'simple'" class="ov-h">
                    <el-input placeholder="请输入关键词" v-model="form.deep_exps.simple.word" class="w-200 mar-r-10"></el-input>
                    <el-input placeholder="请输入来源" v-model="form.deep_exps.simple.source" class="w-200"></el-input>
                  </div>
                  <div v-show="searchType === 'high'" class="ov-h">
                    <div class="ov-h">
                      <el-input placeholder="多个关键词请用英文逗号隔开" v-model="form.deep_exps.high.word" class="w-200 mar-r-10"></el-input>
                      <el-select placeholder="或" class="w-80 mar-r-10" v-model="form.deep_exps.high.word_op">
                        <el-option label="或" value="or"></el-option>
                        <el-option label="与" value="and"></el-option>
                      </el-select>
                      <el-input placeholder="多个排除关键词请用英文逗号隔开" v-model="form.deep_exps.high.not_word" class="w-200 mar-r-10"></el-input>
                      <el-select placeholder="或" class="w-80 mar-r-10" v-model="form.deep_exps.high.not_word_op">
                        <el-option label="或" value="or"></el-option>
                        <el-option label="与" value="and"></el-option>
                      </el-select>
                    </div>
                    <div class="ov-h mar-t-10">
                      <el-input placeholder="多个来源请用英文逗号隔开" v-model="form.deep_exps.high.source" class="w-200 mar-r-10"></el-input>
                      <el-input placeholder="多个排除来源请用英文逗号隔开" v-model="form.deep_exps.high.not_source" class="w-200"></el-input>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div class="hr mar-t_10">
              <span class="line"></span>
              <i class="iconfont" @click="isShowFrom = !isShowFrom" :class="{'icon-xiangshang': isShowFrom, 'icon-xiangxia': !isShowFrom}"></i>
            </div>
            <div class="flex mtlx mar-t-20">
              <span class="mar-r-20 fz-12 b">媒体类型</span>
              <div class="flex-1">
                <div class="flex">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="mediaCheckall" @change="handleCheckAll">
                    {{ mediaFormat('全部') }}
                  </el-checkbox>
                  <el-checkbox-group v-model="form.media" @change="handleChange" class="flex-1">
                    <el-checkbox v-for="(media, index) in mediaGroup" :label="media" :key="index">
                      {{ mediaFormat(media) }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="button-group mar-t-30">
              <el-button type="primary" round v-loading="loading" :disabled="loading" @click="handleClickFilter">查询
              </el-button>
              <el-button round @click="handleClickReset">重置</el-button>
            </div>
          </el-card>
        </el-row>
        <el-card class="box-card mar-t-20 xxlb">
          <div class="mod-title mar-t-20">信息列表</div>
          <div class="box-shadow"></div>
          <xxlb ref="list" :orderOptions="orderOptions" @exportData="exportData" @done="loadInfoDataDone" />
        </el-card>
      </div>
    </div>
    <article-detail ref="detail" :materials="materials"/>
  </el-container>
</template>
<script type="application/javascript">
  import {
    timeGroup,
    emotionGroup,
    infoMatchGroup,
    infoTypeGroup,
    mergeGroup,
    areaGroup,
    weiboGroup,
    weiboUserGroup,
    languageGroup,
    searchTypeGroup,
    orderGroup,
  } from '../constants';
  import {getInfoList} from '../api';
  import articleDetail from '@components/common/article-detail.vue';
  import xxlb from '@/components/common/list.vue';
  import {numberFormat, parseDateRange, getAuthToken, queryString, handleDownload} from '@/utils/helpers.js';
  import {medias as mediaGroup, orderOptions, province,provinceOptions} from '@/utils/constants';
  export default {
    name: 'ComponetInfo',
    components: {
      articleDetail,
      xxlb
    },
    props: {
      word: {
        type: String,
        default: '',
      },
      isEmitSearch: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        orderOptions: orderOptions,
        provinceOptions,
        isIndeterminate: false,
        loading: false,
        timeGroup,
        mediaGroup,
        emotionGroup,
        infoMatchGroup,
        infoTypeGroup,
        mergeGroup,
        areaGroup,
        weiboGroup,
        weiboUserGroup,
        languageGroup,
        orderGroup,
        searchTypeGroup,
        searchType: 'simple',
        province: province,
        isMediaIndeterminate: true, // 媒体类型全选按钮样式控制
        mediaCheckall: true, // 媒体类型全选
        labelPosition: 'left',
        isShowFrom: false,
        customerArea: [],
        // 信息 form 参数
        form: {
          type: 'info',
          page: 1,
          size: 20,
          order: 'intelligence',
          stat: 'yes',
          span: timeGroup[1].value,
          media: [...mediaGroup],
          msg_level: 'normal',
          emotion: emotionGroup[0].value,
          merge: mergeGroup[0].value,
          area_cn: areaGroup[0].value,
          city: '',
          area_customer: province[0],
          weibo_forward: weiboGroup[0].value,
          weibo_usertype: weiboUserGroup[0].value,
          is_policy: infoTypeGroup[0].value,
          field: infoMatchGroup[0].value,
          lang: languageGroup[0].value,
          deep_exps: {
            simple: {
              word: '',
              source: '',
            },
            high: {
              word: '',
              word_op: '',
              not_word: '',
              source: '',
              not_source: '',
              not_word_op: 'or',
            },
          },
        },
        initForm: {},
        data: [], // 用于筛选的数据
        checkedAll: false,
        checkbox: false,
        checkedData: {},
        handleData: false,
        joinMaterial: {visible: false, selectedId: '', loading: false},
        materials: [],
        dialogVisible: false,
        statistics: null
      };
    },
    computed: {
      emit: {
        get() {
          return this.isEmitSearch;
        },
        set(val) {
          this.$emit('update:isEmitSearch', val);
        },
      },
    },
    created() {
      this.$nextTick(() => {
        this.handleClickFilter();
      })
    },
    watch: {
      emit: function (val) {
        if (val) {
          this.loadInfoData();
        }
      },
    },
    mounted() {
      let where = JSON.parse(JSON.stringify(this.form));
      delete where.type;
      delete where.page;
      delete where.size;
      delete where.order;
      this.initForm = where;
    },
    methods: {
      // 信息接口
      loadInfoData() {
        const query = this.combinForm();
        if (!query) return false;
        this.loading = true;
        this.$refs['list'].loadData(`${window.service.api}/search/information`, getInfoList(query), false, 'search-info');
      },
      loadInfoDataDone(data, params = {}) {
        if (data !== undefined) {
          this.statistics = data.statistics;
        }
        Object.assign(this.form, params)
        this.loading = false;
      },
      handleCityChange(v) {
        if (v && v.length >= 2) {
          Object.assign(this.form, {
            area_customer: v[0],
            city: v[0] !== v[1] ? v[1] : ''
          })
        }
      },
      mediaFormat(media) {
        if (!this.statistics) {
          return `${media}(0)`;
        }
        if (media === '全部') {
          let total = _.sumBy(this.statistics, 'num');
          return `全部(${numberFormat(total)})`;
        }
        let row = _.find(this.statistics, (o) => {
          return o.media === media;
        });
        let rowTotal = row === undefined ? 0 : numberFormat(row.num);
        return `${media}(${rowTotal})`;
      },
      buildParams() {
        const {
          emotion,
          lang,
          merge,
          media,
          deep_exps,
          area_cn,
          city,
          field,
          area_customer,
          weibo_forward,
          weibo_usertype
        } = this.form;
        if (media.length < 1) {
          this.$message.warning("请选择媒体类型，媒体类型不能为空");
          return false;
        }
        let span = parseDateRange(this.form.span);
        let {searchType} = this
        const params = {
          span: span,
          emotion: emotion,
          lang: lang,
          media: media.join(','),
          area: area_cn === 'customer' ? area_customer : area_cn,
          city: area_cn === 'customer' ? city : '',
          focus: JSON.stringify(deep_exps[`${searchType}`]),
          field: field,
          similar: merge,
          word: this.word,
          weibo_forward: weibo_forward,
          weibo_usertype: weibo_usertype,
          search: 'yes',
          source_type: 'info'
        }
        return params;
      },
      // 查询
      handleClickFilter() {
        this.loadInfoData();
      },
      exportData(total) {
        const params = this.buildParams();
        if (!params) return false;
        params.token = getAuthToken();
        const query = decodeURIComponent(queryString(params, true));

        handleDownload(`${window.service.api}/search/excel${query}`, 'GET', `${window.$moment().format("YYYY-MM-DD")}.xlsx`, null, total, () => {
          this.$refs['list'].exportLoading = false
        })
      },
      errorTips(str) {
        this.$message.error(str);
        return false;
      },
      // 查询前验证
      validForm() {
        const {
          media,
          deep_exps: {simple, high},
        } = this.form;
        const {searchType} = this;
        if (!media.length) {
          this.errorTips('请选择媒体类型！');
        } else {
          return true;
        }
      },
      // 组合参数
      combinForm() {
        const {
          media,
          area_cn,
          city,
          area_customer,
          deep_exps,
          ...commonObj
        } = this.form;
        const {searchType} = this;
        if (_.size(media) < 1) {
          this.$message.warning("请选择媒体类型，媒体类型不能为空");
          return false;
        }
        const obj = Object.assign(commonObj, {
          word: this.word,
          media: media.join(','),
          area_cn: area_cn === 'customer' ? area_customer : area_cn,
          city: area_cn === 'customer' ? city : '',
          deep_exps: deep_exps[`${searchType}`]
        });
        return obj;
      },
      // 取消
      handleClickReset() {
        let where = JSON.parse(JSON.stringify(this.initForm))
        Object.assign(this.form, where)
        this.form.deep_exps = where.deep_exps
      },
      // 点击选择
      handleChange(value) {
        let checkedCount = value.length;
        this.mediaCheckall = checkedCount === mediaGroup.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < mediaGroup.length;
      },
      // 全选按钮
      handleCheckAll(val) {
        // 将反选按钮重置
        this.form.media = val ? mediaGroup : [];
        this.isIndeterminate = false;
      },
      target(url) {
        window.open(url, '_blank');
      },
      detail(rowkey) {
        let pid = '';
        let keywords = [];
        const {word, source, not_word, not_source} = this.form.deep_exps;
        let focusWords = word || source || not_word || not_source;
        if (_.size(focusWords) >= 1) {
          let words = focusWords.split(',');
          _(words).forEach((word, index) => {
            if (keywords.indexOf(word) === -1) keywords.push(word);
          });
        }
        this.$refs.detail.showDetail(rowkey, 'list', keywords, pid);
      }
    },
  };
</script>
<style scoped>
.search-info >>> .el-card__body {padding:0 40px 20px;}
.search-info .form-box {margin-top: 0;background: none;padding: 0;}
.search-source >>> .el-card{border:none;}
/* 2.0.6迭代 */
.search-info .demo-form-inline .w-50{width:50%;display: inline-block;}
.search-info >>> .el-form-item__label{font-weight: 600;}
.search-info .hr{height:12px;position: relative;width:100%;}
.search-info .hr .line{height:1px;background:#EBECF0;width:100%;position: absolute;left:0;top:6px;}
.search-info .hr .iconfont{position: absolute;left:calc(50% - 5px);top:0;z-index:5;padding:0 5px;background:#fff;cursor: pointer;}
</style>
