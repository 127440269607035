<template>
  <el-container>
    <div class="w-100">
      <div class="content">
        <el-row>
          <!-- 搜索组件 -->
          <el-card class="box-card yq-form search-first-box">
            <div class="mod-title">
              <span>全网智搜</span>
            </div>
            <div class="box-shadow mar-t-20"></div>
          </el-card>
          <el-card class="box-card top-shadow-none">
            <div class="mar-t-5 ov-h mar-b-20">
              <el-radio-group v-model="form.type"
                class="f-l mar-t-15 mar-r-20">
                <el-radio v-for="item in typesGroup"
                  :key="item.value"
                  :label="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
              <div class="f-l mar-t-5 mar-r-10">
                <el-input
                  v-model="form.word"
                  placeholder="请输入信息关键词"
                  @keyup.enter.native="handleClickFilter" suffix-icon="iconfont icon-sousuo"></el-input>
              </div>
              <span class="f-l more-summary mar-t-5">
                如需添加更多关键词，请
                <var class="cor-blue underline cur-p" @click="createPlan">创建监测方案</var>
              </span>
              <el-button plain
                class="zk f-r mar-t-5 hide"
                @click="handleSwitchFilterForm">
                {{ isShowFilterForm ? '收起筛选' : '高级筛选' }}</el-button>
            </div>
          </el-card>
          <keep-alive>
            <component
              ref="search"
              :is="currComponent"
              :word.sync="form.word"
              :is-show-from="isShowFilterForm"
              :is-emit-search.sync="isEmitSearch"
              @cancel="handleSwitchFilterForm" />
          </keep-alive>
        </el-row>
      </div>
    </div>
  </el-container>
</template>
<script>
import {
  typesGroup,
} from './constants';
import { ComponetInfo, ComponetPolicy, ComponetSource } from './components';
export default {
  name: 'PolicyIndex',
  components: {
    infoC: ComponetInfo,
    sourceC: ComponetSource,
    policyC: ComponetPolicy,
  },
  data() {
    return {
      typesGroup,
      isShowFilterForm: false, // 收起/展开 form
      isEmitSearch: true, // 是否触发搜索
      currComponent: '',
      form: {
        type: '',
        word: '',
      },
    }
  },
  watch: {
    'form.type': function(val) {
      this.currComponent = `${val}C`;
    },
  },
  created() {
    // 设置当前的类型和关键词
    this.setTypeAndWord();
  },
  methods: {
    // 设置当前的类型和关键词
    setTypeAndWord() {
      const { type, value } = this.$route.query;
      this.form.type = type || typesGroup[0].value;
      this.form.word = value;
    },
    handleClickFilter() {
      this.$refs.search.handleClickFilter();
    },
    // 收起/展开
    handleSwitchFilterForm() {
      this.isShowFilterForm = !this.isShowFilterForm;
    },
    createPlan ()
    {
      this.$router.push({name: 'yq', params: {createPlan: true}})
    }
  },
}
</script>
<style scoped>
.yq-form .mod-title{margin-left: 0;}
.el-card.box-card{border:none;}
.search-first-box >>> .el-card__body{padding-bottom:0;}
.top-shadow-none >>> .el-card__body{padding:0 40px;}
.more-summary{line-height: 32px;font-size: 12px;color:#7c7c7c;}
</style>
