<template>
  <el-container>
    <div class="w-100 search-source mar_t-10">
      <div>
        <el-row>
          <el-card class="top-shadow-none">
            <el-form label-width="100px" class="form-box demo-form-inline">
              <el-form-item label="时间范围" class="w-50">
                <el-radio-group v-model="form.span" key="1">
                  <el-radio v-for="item in timeGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="排序方式" class="w-50">
                <el-select v-model="form.order" placeholder="请选择" class="w-200">
                  <el-option
                    v-for="(item, index) in orderOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="信息等级" class="w-50">
                <el-radio-group v-model="form.msg_level">
                  <el-radio label="all">全部</el-radio>
                  <el-radio label="normal">精准信息</el-radio>
                  <el-radio label="garbage">噪音信息</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="情感属性" class="w-50">
                <el-radio-group v-model="form.emotion" key="3">
                  <el-radio v-for="item in emotionGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="相似合并" class="w-50">
                <el-radio-group v-model="form.merge" key="6">
                  <el-radio v-for="item in mergeGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="信息匹配" class="w-50">
                <el-radio-group v-model="form.field" key="4">
                  <el-radio v-for="item in infoMatchGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <el-form label-width="100px" class="form-box demo-form-inline"
              :label-position="labelPosition" v-show="isShowFrom">
              <el-form-item label="信息地区" class="w-50">
                <el-radio-group v-model="form.area_cn" key="7">
                  <el-radio v-for="item in areaGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
                <el-cascader
                    v-model="customerArea"
                    :options="provinceOptions"
                    @change="handleCityChange"
                    class="mar-l-10"
                    v-if="form.area_cn === 'customer'" />
              </el-form-item>
              <el-form-item label="语言" class="w-50">
                <el-radio-group v-model="form.lang" key="11">
                  <el-radio v-for="item in languageGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="微博筛选" class="w-50">
                <span class="mar-r-10 fz-12">微博类型</span>
                <el-select v-model="form.weibo_forward" placeholder="活动区域" class="mar-r-20 w-120">
                  <el-option label="不限" value="all"></el-option>
                  <el-option label="转发" value="yes"></el-option>
                  <el-option label="原创" value="no"></el-option>
                </el-select>
                <span class="mar-r-10 fz-12">微博认证</span>
                <el-select v-model="form.weibo_usertype" placeholder="活动区域" class="w-120">
                  <el-option label="不限" value="all"></el-option>
                  <el-option label="普通用户" value="普通用户"></el-option>
                  <el-option label="黄V" value="黄V"></el-option>
                  <el-option label="金V" value="金V"></el-option>
                  <el-option label="蓝V" value="蓝V"></el-option>
                  <el-option label="达人" value="达人"></el-option>
                  <el-option label="微博女郎" value="微博女郎"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="信息类型" class="w-50">
                <el-radio-group v-model="form.is_policy" key="5">
                  <el-radio v-for="item in infoTypeGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="微博类型" class="hide">
                <el-radio-group v-model="form.weibo_forward" key="9">
                  <el-radio v-for="item in weiboGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="微博用户类型" class="hide">
                <el-radio-group v-model="form.weibo_usertype" key="10">
                  <el-radio v-for="item in weiboUserGroup"
                    :key="item.value"
                    :label="item.value">{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="再检索" class="hide">
                <el-select key="12"
                  placeholder="简单检索"
                  class="w-120 f-l mar-r-20"
                  v-model="searchInfoType">
                  <el-option v-for="item in searchTypeGroup"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
                <div class="f-l mar-l-10">
                  <div v-show="searchInfoType === 'simple'" class="ov-h">
                    <el-input placeholder="请输入关键词"
                      v-model="form.deep_exps.simple.word"
                      class="w-200 mar-r-10"></el-input>
                    <el-input placeholder="请输入来源"
                      v-model="form.deep_exps.simple.source"
                      class="w-200 mar-r-10"></el-input>
                  </div>
                  <div v-show="searchInfoType === 'high'" class="ov-h">
                    <div class="ov-h">
                      <el-input placeholder="多个关键词请用英文逗号隔开" v-model="form.deep_exps.high.word" class="w-200 mar-r-10"></el-input>
                      <el-select placeholder="或"
                        class="w-80 mar-r-10"
                        v-model="form.deep_exps.high.word_op">
                        <el-option label="或" value="or"></el-option>
                        <el-option label="与" value="and"></el-option>
                      </el-select>
                      <el-input placeholder="多个排除关键词请用英文逗号隔开"
                        v-model="form.deep_exps.high.not_word"
                        class="w-200 mar-r-10"></el-input>
                      <el-select placeholder="或"
                        class="w-80"
                        v-model="form.deep_exps.high.not_word_op">
                        <el-option label="或" value="or"></el-option>
                        <el-option label="与" value="and"></el-option>
                      </el-select>
                    </div>
                    <div class="ov-h mar-t-10">
                      <el-input placeholder="多个来源请用英文逗号隔开" v-model="form.deep_exps.high.source" class="w-200 mar-r-10"></el-input>
                      <el-input placeholder="多个排除来源请用英文逗号隔开" v-model="form.deep_exps.high.not_source" class="w-200 mar-r-10"></el-input>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div class="hr mar-t_10">
              <span class="line"></span>
              <i class="iconfont" @click="isShowFrom = !isShowFrom" :class="{'icon-xiangshang': isShowFrom, 'icon-xiangxia': !isShowFrom}"></i>
            </div>
            <!-- 按钮组 -->
            <div class="flex mtlx mar-t-20">
              <span class="mar-r-20 fz-12 b">媒体类型</span>
              <div class="flex-1">
                <div class="flex">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="mediaCheckall" @change="handleCheckAll">
                    {{ mediaFormat('全部') }}
                  </el-checkbox>
                  <el-checkbox-group v-model="form.media" @change="handleChange" class="flex-1">
                    <el-checkbox v-for="(media, index) in mediaGroup" :label="media" :key="index">
                      {{ mediaFormat(media) }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="button-group mar-t-20">
              <el-button type="primary" round
                v-loading="loading"
                :disabled="loading"
                @click="handleClickFilter">查询
              </el-button>
              <el-button round @click="handleClickReset">重置</el-button>
            </div>
          </el-card>
        </el-row>
        <el-card class="box-card mar-t-20 xxlb">
          <div class="mod-title mar-t-20">信息列表</div>
          <div class="box-shadow"></div>
          <xxlb ref="list" :orderOptions="orderOptions" @exportData="exportData" @done="loadListDone" />
        </el-card>
      </div>
    </div>
    <article-detail ref="detail" :materials="materials"/>
  </el-container>
</template>
<script type="application/javascript">
  import {
    timeGroup,
    emotionGroup,
    infoMatchGroup,
    infoTypeGroup,
    mergeGroup,
    areaGroup,
    weiboGroup,
    weiboUserGroup,
    languageGroup,
    searchTypeGroup,
    orderGroup,
    sourceImgMap,
  } from '../constants';
  import {getSourceInfoList} from '../api';
  import articleDetail from '@components/common/article-detail.vue';
  import xxlb from '@components/common/list.vue';
  import {numberFormat, parseDateRange, getAuthToken, queryString, handleDownload} from '@/utils/helpers.js';
  import {medias as mediaGroup, orderOptions, province,provinceOptions} from '@/utils/constants';
  import {lists as materialList, add_item} from "@/api/material";
  export default {
    name: 'ComponetSource',
    components: {
      articleDetail,
      xxlb
    },
    props: {
      word: {
        type: String,
        default: '',
      },
      isEmitSearch: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        orderOptions: orderOptions,
        provinceOptions,
        isIndeterminate: false,
        loading: false,
        timeGroup,
        mediaGroup,
        emotionGroup,
        infoMatchGroup,
        infoTypeGroup,
        mergeGroup,
        areaGroup,
        weiboGroup,
        weiboUserGroup,
        languageGroup,
        orderGroup,
        searchTypeGroup,
        sourceImgMap,
        searchInfoType: 'simple', // 检索类型 - 信息 tab
        sourceSearchType: 'simple', // 检索类型 - 来源 tab
        isMediaIndeterminate: true, // 媒体类型全选按钮样式控制 - 信息 tab
        isSouceMediaIndeterminate: true, // 媒体类型全选按钮样式控制 - 来源 tab
        mediaCheckall: true, // 媒体类型全选 - 信息 tab
        sourceMeidiaCheckall: true, // 媒体类型全选 - 来源 tab
        labelPosition: 'left',
        currTab: 'info',
        // 公共参数
        commQuery: {
          page: 1,
          size: 20,
          stat: 'yes',
        },
        customerArea: [],
        // 信息 form 参数
        form: {
          type: 'info',
          order: 'intelligence',
          span: timeGroup[1].value,
          media: [...mediaGroup],
          emotion: emotionGroup[0].value,
          msg_level: 'normal',
          merge: mergeGroup[0].value,
          area_cn: areaGroup[0].value,
          city: '',
          area_customer: province[0],
          weibo_forward: weiboGroup[0].value,
          weibo_usertype: weiboUserGroup[0].value,
          is_policy: infoTypeGroup[0].value,
          field: infoMatchGroup[0].value,
          lang: languageGroup[0].value,
          deep_exps: {
            simple: {
              word: '',
              source: '',
            },
            high: {
              word: '',
              word_op: '',
              not_word: '',
              source: '',
              not_source: '',
              not_word_op: 'or',
            },
          },
        },
        initForm: {},
        // 来源 form 参数
        sourceForm: {
          media: [...mediaGroup],
          weibo_usertype: weiboUserGroup[0].value,
          lang: languageGroup[0].value,
          deep_exps: {
            simple: {
              source: '',
            },
            high: {
              source: '',
              not_source: '',
            },
          },
        },
        // 信息总量统计
        infoTotal: {
          total: 0, // 总量
          list: [], // 每一项的统计
        },
        sourceTotal: {
          total: 0,
          list: [],
        },
        province: province,
        listPaginationTotal: 0, // 信息 tab 分页数据
        // sourceTotal: 0, // 来源 tab 分页数据
        infoData: [], // 信息查询结果
        sourceData: [], // 来源查询结果
        isSearch: false,
        checkedAll: false,
        checkbox: false,
        checkedData: {},
        handleData: false,
        joinMaterial: {visible: false, selectedId: '', loading: false},
        materials: [],
        dialogVisible: false,
        statistics: null,
        isShowFrom: false
      };
    },
    computed: {
      keywords: {
        get() {
          return this.word;
        },
        set(val) {
          this.$emit('update:word', val);
        },
      },
      emit: {
        get() {
          return this.isEmitSearch;
        },
        set(val) {
          this.$emit('update:isEmitSearch', val);
        },
      },
    },
    created() {
      this.$nextTick(() => {
        this.handleClickFilter();
      });
      let where = JSON.parse(JSON.stringify(this.form));
      delete where.type;
      delete where.order;
      this.initForm = where;
    },
    watch: {
      word: function (val) {
        // 关键词更改 是否触发搜索
        if (this.isSearch) {
          this.handleClickFilter();
        }
      },
      emit: function (val) {
        if (val) {
          if (this.currTab === 'info') {
            this.loadInfoData();
          }
        }
      },
    },
    mounted() {
      this.loadMaterial();
    },
    methods: {
      // 查询信息接口
      loadInfoData() {
        const query = this.combinForm();
        if (!query) return false;
        this.loading = true;
        this.$nextTick(() => {
          this.$refs['list'].loadData(`${window.service.api}/search/source`, getSourceInfoList(query), false, 'search-source');
        })
      },
      loadListDone(data, params = {}) {
        if (data !== undefined) {
          this.statistics = data.statistics;
        }
        Object.assign(this.form, params)
        this.loading = false;
      },
      handleCityChange(v) {
        if (v && v.length >= 2) {
          Object.assign(this.form, {
            area_customer: v[0],
            city: v[0] !== v[1] ? v[1] : ''
          })
        }
      },
      mediaFormat(media) {
        if (!this.statistics) {
          return `${media}(0)`;
        }
        if (media === '全部') {
          let total = _.sumBy(this.statistics, 'num');
          return `全部(${numberFormat(total)})`;
        }
        let row = _.find(this.statistics, (o) => {
          return o.media === media;
        });
        let rowTotal = row === undefined ? 0 : numberFormat(row.num);
        return `${media}(${rowTotal})`;
      },
      buildParams() {
        const form = this.combinForm();
        if (!form) return false;
        let {span} = this.form;
        span = parseDateRange(span);
        const params = {
          span: span,
          emotion: form.emotion,
          lang: form.lang,
          media: form.media,
          area: form.area_cn,
          city: form.city,
          focus: JSON.stringify(form.deep_exps),
          field: form.field,
          similar: form.merge,
          word: this.word,
          weibo_forward: form.weibo_forward,
          weibo_usertype: form.weibo_usertype,
          is_policy: form.is_policy,
          search: 'yes',
          source_type: 'source'
        }
        return params;
      },
      errorTips(str) {
        this.$message.error(str);
        return false;
      },
      // 组合参数 信息
      combinFormInfo() {
        const {
          media,
          area_cn,
          city,
          area_customer,
          deep_exps,
          ...commonObj
        } = this.form;
        const {searchInfoType} = this;
        const obj = Object.assign(commonObj, {
          type: this.currTab,
          media: media.join(','),
          city: area_cn === 'customer' ? city : '',
          area_cn: area_cn === 'customer' ? area_customer : area_cn,
          deep_exps: Object.assign({}, deep_exps[`${searchInfoType}`])
        });
        return obj;
      },
      combinForm() {
        if (this.form.media.length < 1) {
          this.$message.warning("请选择媒体类型，媒体类型不能为空");
          return false;
        }
        // 根据当前的 tabs 执行不同的逻辑
        const res = this.combinFormInfo();
        Object.assign(res, this.commQuery, {word: this.keywords});
        return res;
      },
      // 查询
      handleClickFilter() {
        this.loadInfoData();
      },
      // 选择
      checkChange(checked, rowkey) {
        if (checked == false) {
          this.checkedAll = false;
        } else {
          // 判断全选状态
          var state = true;
          _(this.infoData).forEach((item, i) => {
            if (!item.checked) state = false;
          });
          this.checkedAll = state;
        }
        this.checkedData[rowkey] = checked;
      },
      cancelSelected() {
        var _this = this;
        this.checkbox = false;
        this.checkedAll = false;
        var selected = {};
        for (let key in this.checkedData) {
          selected[key] = false;
        }
        this.checkedData = selected;
        _(this.infoData).forEach((item, i) => {
          _this.infoData[i].checked = false;
        });
      },
      // 全选
      allChecked() {
        this.checkedAll = true;
        this.checkbox = true;
        _(this.infoData).forEach((item, i) => {
          this.checkedData[item.rowkey] = true;
          this.infoData[i].checked = true;
        });
      },
      cancelAllChecked() {
        var _this = this;
        this.checkedAll = false;
        _(this.infoData).forEach((item, i) => {
          _this.checkedData[item.rowkey] = false;
          _this.infoData[i].checked = false;
        });
      },
      // 下载 excel
      downloadSheet() {
        var ids = [];
        for (let id in this.checkedData) {
          if (this.checkedData[id]) ids[ids.length] = id;
        }
        if (ids.length < 1) {
          this.$message.error('请选择要下载的素材');
          return false;
        }
        var formDom = window.document.getElementById('download_sheet');
        formDom.action = service.api + '/message/sheet';
        var rowkeys = window.document.getElementById('download_sheet_rowkeys');
        rowkeys.value = ids.join(',');
        formDom.submit();
        this.cancelSelected();
      },
      showJoinDialog() {
        var ids = [];
        for (let id in this.checkedData) {
          if (this.checkedData[id]) ids[ids.length] = id;
        }
        if (ids.length < 1) {
          this.$message.error('请选择要加入的素材');
          return false;
        }
        this.joinMaterial.visible = !this.joinMaterial.visible;
      },
      orderChange(order) {
        Object.assign(this.commQuery, {
          page: 1,
          size: 20,
        });
        this.loadInfoData();
      },
      exportData(total) {
        const params = this.buildParams();
        params.token = getAuthToken();
        const query = decodeURIComponent(queryString(params, true));

        handleDownload(`${window.service.api}/search/excel${query}`, 'GET', `${window.$moment().format("YYYY-MM-DD")}.xlsx`, null, total, () => {
          this.$refs['list'].exportLoading = false
        })
      },
      loadMaterial() {
        materialList().then(res => {
          if (res.data.state) {
            var result = res.data.data;
            this.materials = result;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('加载收藏夹失败，服务错误');
        });
      },
      addMaterialAction(materialId) {
        if (!materialId) {
          this.$message.error('请选择收藏夹');
          return false;
        }
        var ids = [];
        for (let id in this.checkedData) {
          if (this.checkedData[id]) ids[ids.length] = id;
        }
        if (ids.length < 1) {
          this.$message.error('请选择要加入的素材');
          return false;
        }
        this.joinMaterial.loading = true;
        add_item({
          id: materialId,
          ids: ids.join(','),
        }).then(res => {
          if (res.data.state) {
            this.$message.success('添加成功');
            this.joinMaterial.loading = false;
            this.joinMaterial.visible = false;
            this.cancelSelected();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('添加素材失败，服务错误');
        });
      },
      // 取消
      handleClickReset() {
        let where = JSON.parse(JSON.stringify(this.initForm))
        Object.assign(this.form, where)
        this.form.deep_exps = where.deep_exps
      },
      // 点击选择
      handleChange(value) {
        let checkedCount = value.length;
        this.mediaCheckall = checkedCount === mediaGroup.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < mediaGroup.length;
      },
      // 全选按钮
      handleCheckAll(val) {
        // 将反选按钮重置
        // 将反选按钮重置
        this.form.media = val ? mediaGroup : [];
        this.isIndeterminate = false;
      },
      // 来源tab 的媒体类型选择
      handleSourceMediaCheckAll(val) {
        this.sourceForm.media = val ? mediaGroup : [];
        this.isSouceMediaIndeterminate = false;
      },
      handleCheckedSourceMediaChange(value) {
        const checkedCount = value.length;
        this.sourceMeidiaCheckall = checkedCount === mediaGroup.length;
        this.isSouceMediaIndeterminate =
          checkedCount > 0 && checkedCount < mediaGroup.length;
      },
      target(url) {
        window.open(url, '_blank');
      },
      detail(rowkey) {
        let pid = '';
        let keywords = [];
        const {word, source, not_word, not_source} = this.form.deep_exps;
        let focusWords = word || source || not_word || not_source;
        if (_.size(focusWords) >= 1) {
          let words = focusWords.split(',');
          _(words).forEach((word, index) => {
            if (keywords.indexOf(word) === -1) keywords.push(word);
          });
        }
        this.$refs.detail.showDetail(rowkey, 'list', keywords, pid);
      },
      addRowMaterialAction(material, id) {
        if (!material.selectId) {
          this.$message.error('请选择素材库');
          return false;
        }
        material.loading = true;
        add_item({
          id: material.selectId,
          ids: id,
        }).then(res => {
          if (res.data.state) {
            this.$message.success('添加成功');
            material.loading = false;
            material.visible = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('添加素材失败，服务错误');
        });
      },
      handleSetWord(word) {
        this.keywords = word;
        this.isSearch = true;
        this.currTab = 'info';
      },
    },
  };
</script>
<style scoped>
.search-source >>> .el-card__body {padding:0 40px 20px;}
.search-source .form-box {margin-top: 0;background: none;padding: 0;}
.search-source >>> .el-card{border:none;}
/* 2.0.6迭代 */
.search-source .demo-form-inline .w-50{width:50%;display: inline-block;}
.search-source >>> .el-form-item__label{font-weight: 600;}
.search-source .hr{height:12px;position: relative;width:100%;}
.search-source .hr .line{height:1px;background:#EBECF0;width:100%;position: absolute;left:0;top:6px;}
.search-source .hr .iconfont{position: absolute;left:calc(50% - 5px);top:0;z-index:5;padding:0 5px;background:#fff;cursor: pointer;}
</style>
